/* @import url(//db.onlinewebfonts.com/c/5ab304ade0d4f62d027d12464ca69443?family=Apple+Color+Emoji); */

body {
  background-color: black;
  background-image: url('./assets/bg.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  /* color: white; */
}

body {
  height: 100vh;
}

#root {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  height: 100%;
}

@font-face {
  font-family: 'Tangelo';
  src: local('Tangelo'), url(./assets/Tangelo.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'SeasideResortNF';
  src: local('SeasideResortNF'), url(./assets/SEASRN__.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Lucian';
  src: local('Lucian'), url(./assets/lucien/Lucian.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.about-traits {
  background-image: url('./assets/bg_black.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 300px !important; */
  /* color: white; */
}

.about-traits-img {
  max-width: 412px !important;
  max-height: 378px !important;
}

.about-rarity-img {
  max-width: 897px !important;
  max-height: 273px !important;
}


.txt-shadow {
  text-shadow: rgb(0 0 0 / 40%) 0px 4px 5px
}

.top-text-name {
  color: white;
  font-family: Tangelo;
  font-size: 38px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.traits-text-name {
  color: white;
  font-family: Tangelo;
  font-size: 54px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}


.top-text {
  color: white;
  font-family: Tangelo;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.minted-text {
  color: white;
  font-family: Tangelo;
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.alloc-text {
  color: white;
  font-family: Tangelo;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}


.soy-gif {
  width: 394px;
  height: 394px;
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #888;
  border-radius: 48px;
  background-color: #222;
  border: none;
  opacity: 1 !important;
}

.btn-primary {
  outline: none !important;
  border: none !important;
}

.btn-primary:focus {
  background-color: #fff;
  color: #000;
  outline: none;
  border: none !important;
}

a:hover {
  color: #e5c100;
}

.external-link:hover {
  color: #e5c100;
}

.container {
  max-width: 100%;
}
.top-logo {
  /* margin-left: 285px; */
}

.main-box {
  height: 45vh !important;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media only screen and (max-height: 900px) {
  .main-box {
    height: 30vh !important;
  }
}

.hat-img {
  width: 80px;
  height: 80px;
}
.main-bottom {
  /* position:absolute; */
  left:0;
  bottom:0;
  right:0;
}
.invalid-feedback {
  color: yellow;
  margin-top: 4px !important;
  text-align: center;
  font-family: Tangelo;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.main-button-counter {
  padding: 0px 38px 0px 38px !important;
}

.main-button {
  width: 370px !important;
  padding-top: 22px !important;
  height: 90px;
  min-width: 270px;
  border-radius: 48px;
  background-color: #fff;
  font-family: Tangelo;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.main-button:hover {
  background-color: #a89ab0;
}

.connect-top {
  width: 171px;
  height: 54px;
  border-radius: 30px;
  background-color: #fff;
  font-family: Tangelo;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.external-link {
  width: 16px;
  height: 16px;
  padding: 1px;
  object-fit: contain;
  color: #a89ab0;
  margin-bottom: 3px !important;
}

.balance-top {
  width: 83px;
  margin-top: 15px !important;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: Tangelo;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: white;
}

.connect-top:disabled, .connect-top.disabled {
  width: 171px;
  height: 54px;
  border-radius: 30px;
  background-color: #222;
  border: none;
}

.connect-top:hover {
  background-color: #a89ab0;
}

.flowty-alert {
  border-radius: 10px !important;
  border: solid 3px rgba(136, 136, 136, 0.5) !important;
  background-color: #222 !important;
  color: white;
  padding-right: 1rem !important;
}

.alert-link-soy {
  color: #a89ab0;
  text-decoration: none !important;
}

.alert-link-contract {
  color: white;
  text-decoration: none !important;
}

.flowty-alert-sub {
  font-family: Tangelo;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #888;
}

.flowty-alert .alert-heading {
  font-family: Tangelo;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.flowty-alert .btn-close {
  color: white;
  background: transparent url('./assets/cross-big.svg') center/1em auto no-repeat;
}

.alert-flowty {
  font-family: Tangelo;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.top-connect-box {
  margin-right: 20px;
}

img.Cross-big {
  width: 40px;
  height: 40px;
  padding: 6px;
  object-fit: contain;
  cursor: pointer;
}

img.Cross-big-top {
  width: 40px;
  height: 40px;
  padding: 6px;
  margin-left: -20px;
  margin-top: 9px;
  /* padding-right: 20px; */
  object-fit: contain;
  cursor: pointer;
  opacity: 0.5;
}

.minted-label {
  font-family: Tangelo;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.mint-button {
  width: 272px;
  height: 54px;
  border-radius: 30px;
  background-color: #fff;
  border: none;

  margin-top: 15px;

  font-family: Tangelo;
  font-size: 22px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: black;
}

.mint-button:hover {
  background-color: #e5c100; /* Green */
}

.sale-input-box {
  width: 284px;
  height: 54px;
  border-radius: 30px;
  border: none;
  background-color: #222;
}

.sale-input {
  /* width: 264px; */
  height: 54px;
  padding-left: 32px !important;
  border-radius: 30px;
  background-color: transparent;
  color: white;
  border: none;
  text-align: center;

  font-family: Tangelo;
  font-size: 15px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  
}
.sale-input:disabled {
  background-color: transparent;
}
.sale-input:focus {
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
}
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}

.sale-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #888;
  opacity: 1; /* Firefox */
}

.sale-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #888;
}

.sale-input::-ms-input-placeholder { /* Microsoft Edge */
  color: #888;
}

.sale-max {
  width: 82px !important;;
  height: 44px !important;;
  margin-top: 5px !important;
  margin-left: 15px !important;
  padding: 9px 17px !important;
  border-radius: 30px !important;
  border: none;
  background-color: #f00;

  font-family: Tangelo;
  font-size: 17px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.sale-add {
  width: 44px !important;
  height: 44px !important;
  margin-top: 5px !important;
  /* margin-left: 5px !important; */
  padding: 0px 0px 0px 0px !important;
  border-radius: 30px !important;
  border: none;
  background-color: #fff;

  /* font-family: Tangelo; */
  font-size: 25px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
}

.sale-max-reached {
  font-family: Tangelo;
  font-size: 12px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.sale-max:hover {
  background-color: #6b6b6b; /* Green */
}

.sale-add:hover {
  background-color: #6b6b6b; /* Green */
}

.sale-input:focus, .sale-input:focus{
  background-color: transparent;
  outline: none;
}

.sale-form {
  margin-top: -80px !important;
  padding-left: 25px !important;
}

body.modal-open > :not(.modal) {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.sale-modal {
  /* min-width: 628px !important;
  min-height: 611px !important;
  max-width: 628px !important;
  max-height: 611px !important; */
  border: none !important;
  outline: none !important;
}

.trusted-col-text {
  padding-right: 25px !important;
}
.trusted-col2-text {
  padding-left: 25px !important;
}

.trusted-img2 {
  margin-top: 15px;
}

.trusted-modal .modal-content .modal-header {
  border-bottom: none !important;
  outline: none !important;
}

.trusted-modal .modal-content .modal-header .btn-close {
  background: transparent url(./assets/cross-big.svg) center/1rem auto no-repeat;
}


.trusted-modal-title {
  /* height: 138px !important;
  align-items: start !important;
  vertical-align: top; */

  font-family: Lucian;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  width: 100%;
  width: 680px;
  border-bottom: solid 1px #979797;
}

.trusted-test {
  font-family: Tangelo;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-top: 20px;
}

.trusted-test2 {
  padding-right: 10px !important;
}

.trusted-test .bold-trusted {
  font-weight: 900;
}

.trusted-modal .modal-content .row  {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.trusted-modal .modal-content .row > * {
  padding-left: 0px;
  padding-right: 0px;
}

.trusted-modal .modal-content  {
  /* min-width: 628px !important;
  min-height: 611px !important;
  max-width: 628px !important;
  max-height: 611px !important; */
  background-color: #222 !important;
  border-radius: 10px;
  border: solid 3px rgba(136, 136, 136, 0.5);
}


.trusted-modal-content .modal-content  {
  width: 900px !important;
  min-width: 900px !important;
}

.trusted-modal-content .modal-body   {
  /* min-width: 628px !important;
  min-height: 611px !important;
  max-width: 628px !important;
  max-height: 611px !important; */
  padding: 0px 110px 0px 110px !important;

}

.btn-close {
  color: #fff !important;
}

.special_modal .modal-body {
  background-color: transparent;
}

.special_modal .modal-header {
  background-color: transparent;
  border-bottom: none;
}

.z-high {
  z-index: 1000;
  min-height: 60px !important;
}

.z-low {
  z-index: 0;
}

.special_modal .modal-content {
  border: none !important;
  outline: none !important;
  background-color: transparent;
  margin-left: 105px;
  width: 628px;
  height: 611px;
  min-width: 628px !important;
  min-height: 611px !important;
  max-width: 628px !important;
  max-height: 611px !important;
  background-size: contain;
  background-repeat: no-repeat;
}

.trusted-box {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  margin-right: 15px !important;
  width: 209px;
  height: 151px;
  cursor: pointer;
}

.App-logo {
  pointer-events: none;
}

.message-list {
  position: absolute;
  display: flexbox;
  right: 1%;
  top: 70px;
  z-index: 1100;
}

.connect-button {
  min-width: 120px;
}

.metamask-icon {
  width: 40px;
  height: 40px;
}

.row-border {
  border-bottom: solid 1px #979797;
}

.wallet-connect-hover:hover {
  background-color: rgba(218, 218, 218, 0.2);
  cursor: pointer;
}

.wallet-connect-title {
  font-family: Tangelo;
  font-size: 20px !important;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.wallet-connect-sub {
  font-family: Tangelo;
  font-size: 14px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #979797;
}